<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>

  <vs-table :data="progressesData">

    <template slot="thead">
      <vs-th>Course</vs-th>
      <vs-th>Resets</vs-th>
      <vs-th>Lessons (C / T)</vs-th>
      <vs-th>Current Progress</vs-th>
    </template>

    <template slot-scope="{data}">
      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
        <vs-td :data="tr.course.name">
          {{ tr.course.name }}
        </vs-td>
        <vs-td :data="tr.metadata.total_attempts">
          {{ tr.metadata.total_attempts }}
        </vs-td>
        <vs-td :data="tr.metadata.total_lessons">
          {{ tr.metadata.total_completed_lessons }}
          /
          {{ tr.metadata.total_lessons }}
        </vs-td>
        <vs-td :data="tr.metadata.current_progress">
          {{ Math.round(tr.metadata.current_progress * 100) }}%
        </vs-td>

      </vs-tr>
    </template>
  </vs-table>

</template>

<script>
import {AgGridVue} from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import vSelect from 'vue-select';

export default {
  components: {
    AgGridVue,
    vSelect,
  },
  data() {
    return {

      progressesData: [],
      progressesTotal: 0,

      totalPages: 0,
    };
  },
  watch: {},
  computed: {
    paginationPageSize() {
      return 10;
      // if (this.gridApi) return this.gridApi.paginationGetPageSize();
      // else return 10;
    },
    currentPage: {
      get() {
        return 1;
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      // set(val) {
      //   this.gridApi.paginationGoToPage(val - 1);
      //   this.loadUserBuffer(val);
      // },
    },
  },
  methods: {
    loadUserBuffer(page = 0) {

      if (page === 0) {
        page = this.currentPage;
      }

      this.$http.get(`courses/progress/hydrated?user_id=${this.userId}&amount=${this.paginationPageSize}&page=${page}`)
        .then(response => {

          if (response.data.data) {
            this.progressesData = response.data.data;
          }

          // this.usersData = this.usersData.map(user => {
          //
          //   user.name = `${user.first_name} ${user.last_name}`;
          //   let pictureId = user.profile_picture || 'default';
          //   user.photoURL = `https://portal-cdn.rapid.education/profile-pictures/${pictureId}.jpeg`;
          //   return user;
          // });

        })
        .catch(exception => {

        });

    },
  },
  mounted() {
    this.$http.get(`courses/progress/counts?user_id=${this.userId}&amount=${this.paginationPageSize}`)
      .then(response => {
        if (response.data.data) {

          const data = response.data.data;

          if (data.pages) {
            this.totalPages = data.pages;
            this.progressesTotal = data.total;
          }

        }
      })
      .catch(exception => {

        console.error(exception);

      });

    this.loadUserBuffer(1)
  },
  created() {



  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
};

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
