<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-view">

    <vs-alert :active.sync="user_not_found" color="danger" title="User Not Found">
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link class="text-inherit underline" to="/">All Users</router-link>
      </span>
    </vs-alert>

    <div v-if="user_data" id="user-data">

      <div class="vx-row">
        <div :class="`vx-col lg:w-${user_data.title ? '1/2' : 'full'} w-full`">
          <vx-card class="mb-base" title="Account">

            <!-- Avatar -->
            <div class="vx-row">

              <!-- Avatar Col -->
              <div id="avatar-col" class="vx-col">
                <div class="img-container mb-4">
                  <img :src="user_data.photoURL" class="rounded w-full"/>
                </div>
              </div>

              <!-- Information - Col 1 -->
              <div id="account-info-col-1" class="vx-col flex-1">
                <table>
                  <tr>
                    <td class="font-semibold">First Name</td>
                    <td>{{ user_data.first_name }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Email</td>
                    <td>{{ user_data.email }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Role</td>
                    <td>{{ user_data.role | title('_') }}</td>
                  </tr>
                </table>
              </div>
              <!-- /Information - Col 1 -->

              <!-- Information - Col 2 -->
              <div id="account-info-col-2" class="vx-col flex-1">
                <table>
                  <tr>
                    <td class="font-semibold">Last Name</td>
                    <td>{{ user_data.last_name }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Company</td>
                    <td>{{ user_data.company }}</td>
                  </tr>
                </table>
              </div>
              <!-- /Information - Col 2 -->
              <div id="account-manage-buttons" class="vx-col w-full flex">
                <vs-button :to="{name: 'app-user-edit', params: { userId: $route.params.userId }}" class="mr-4"
                           icon="icon-edit"
                           icon-pack="feather">Edit
                </vs-button>
                <vs-button color="danger" icon="icon-trash" icon-pack="feather" type="border" @click="confirmDeleteRecord">
                  Delete
                </vs-button>
              </div>

            </div>

          </vx-card>
        </div>

        <div v-if="user_data.title" class="vx-col lg:w-1/2 w-full">
          <vx-card class="mb-base" title="Instructor Summary">
            <table>
              <tr>
                <td class="font-semibold">Title</td>
                <td>{{ user_data.title }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Description</td>
                <td>{{ user_data.description }}</td>
              </tr>
            </table>
          </vx-card>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <vx-card class="mb-base" title="Course Progress">
            <user-course-progress :user-id="user_data.id"></user-course-progress>
          </vx-card>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import UserCourseProgress from '@/views/apps/user/user-view/components/UserCourseProgress';
export default {
  components: {UserCourseProgress},
  data() {
    return {
      user_data: null,
      user_not_found: false,
    };
  },
  methods: {
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You are about to delete "${this.user_data.first_name}"`,
        accept: this.deleteRecord,
        acceptText: 'Delete',
      });
    },
    deleteRecord() {
      this.$router.push('/a/users');
      this.showDeleteSuccess();
    },
    showDeleteSuccess() {
      this.$vs.notify({
        color: 'danger',
        title: 'User Not Deleted',
        text: 'We do not currently allow users to be fully deleted',
      });
    },
  },
  created() {

    const userId = this.$route.params.userId;

    this.$http.get(`users/${userId}`)
      .then(response => {

        if (response.data.data) {
          this.user_data = response.data.data;
          const pictureId = this.user_data.profile_picture || 'default';
          this.user_data.photoURL = `https://portal-cdn.rapid.education/profile-pictures/${pictureId}.jpeg`;
        }

      })
      .catch(error => {
        if (error.response.status === 404) {
          this.user_not_found = true;
          return;
        }
        console.error(error);
      });
  },
};

</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }


@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }

}

</style>
